import { useIntl } from 'gatsby-plugin-intl';
import * as React from 'react';
import CheckboxStyle from '../_scss/modules/checkbox.module.scss';
import LoadingOverlay from '../components/common/loading-overlay/loading-overlay';
import PageLayout from '../components/common/page-layout/page-layout';
import Button, { ButtonThemeColor, ButtonThemeType } from '../components/templates/button/button';
import SectionIntended from '../components/templates/section-intended/section-intended';
import { emailCheck } from '../modules/email-check';
import emailjs from 'emailjs-com';

import ContactStyles from './_scss/contact.module.scss';

const ContactPage = () => {
    const intl = useIntl();

    // variables
    const MIN_MESSAGE_LENGTH = 1;
    const SUPPORT = 'Technischer Kundensupport / Ersatzteile';
    const RETOURE = 'Retoure';

    // form fields
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [eMail, setEMail] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [subject, setSubject] = React.useState('');
    const [device, setDevice] = React.useState('');
    const [serialNumber, setSerialNumber] = React.useState('');
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [agreement, setAgreement] = React.useState(false);

    // other
    const [showErrors, setShowErrors] = React.useState(false);
    const [pageState, setPageState] = React.useState<'start' | 'success' | 'error'>('start');
    const [isLoading, setIsLoading] = React.useState(false);
    const [isPhoneMandatory, setIsPhoneMandatory] = React.useState(false);
    const errorClass = ContactStyles.error;

    // functions
    const hasErrors = (): boolean => {
        if (
            !firstName ||
            !lastName ||
            !emailCheck(eMail) ||
            !subject ||
            message.length < MIN_MESSAGE_LENGTH ||
            !agreement
        ) {
            return true;
        }

        if (subject === SUPPORT || subject === RETOURE) {
            if (!device || serialNumber.length < 1) {
                return true;
            }

            if (phoneNumber.length < 1) {
                return true;
            }
        }

        return false;
    };

    const submitForm = (e: any) => {
        e.preventDefault();
        if (!hasErrors()) {
            setIsLoading(true);

            emailjs
                .send(
                    'service_zr40efq',
                    'template_qv93l9b',
                    { firstName, lastName, eMail, message, subject, device, serialNumber, phoneNumber, agreement },
                    'user_r5TYhJKOVybYWqBZqLelR',
                )
                .then(
                    () => {
                        setPageState('success');
                    },
                    () => {
                        setPageState('error');
                    },
                )
                .finally(() => {
                    setIsLoading(false);
                    if (typeof window !== 'undefined') {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }
                });
        } else {
            setShowErrors(true);

            setTimeout(() => {
                const formElement = document.querySelector('.' + errorClass + ', .' + CheckboxStyle.error);

                if (formElement) {
                    try {
                        const position = formElement.getBoundingClientRect();
                        window.scrollTo({
                            top: position.top + window.scrollY - 120,
                            left: 0,
                            behavior: 'smooth',
                        });
                    } catch (e) {}
                }
            }, 250);
        }
    };

    React.useEffect(() => {
        if (subject === SUPPORT || subject === RETOURE) {
            setIsPhoneMandatory(true);
        } else {
            setIsPhoneMandatory(false);
        }
    }, [subject]);

    return (
        <PageLayout title={intl.formatMessage({ id: 'pages.contact.title' })}>
            <div className={ContactStyles.contactPage}>
                <SectionIntended>
                    <h3 className="color-white">KONTAKT</h3>
                    <div className={ContactStyles.grid}>
                        <div className={ContactStyles.address}>
                            <p className="bold color-white">
                                Anschrift:
                                <br />
                                Johnson Health Tech. GmbH
                                <br />
                                Europaallee 51
                                <br />
                                50226 Frechen
                                <br />
                                <br />
                                Kundensupport:
                                <br />
                                +49 (0) 2234 - 9997-300
                                <br />
                                <br />
                                Erreichbarkeit:
                                <br />
                                Montag – Freitag 8.00-20.00 Uhr
                                <br />
                                Samstag 10.00-14.00 Uhr
                            </p>
                        </div>

                        {pageState === 'start' && (
                            <div className={ContactStyles.form}>
                                <div className={ContactStyles.formHead}>
                                    <h6 className="color-white">Hast du eine Frage?</h6>
                                    <p className="color-white">
                                        Sieh am besten erstmal in unseren FAQs nach – viele Fragen sind dort bereits
                                        beantwortet. Und wenn du nicht fündig wirst, nutze einfach das untenstehende
                                        Formular. Wir melden uns dann schnellstmöglich bei dir.
                                    </p>
                                </div>
                                <form>
                                    {/*FIRSTNAME START*/}
                                    <div className={ContactStyles.formField}>
                                        <label htmlFor={'contact-first-name'}>Dein Vorname*</label>
                                        <input
                                            id={'contact-first-name'}
                                            type="text"
                                            maxLength={100}
                                            autoComplete="given-name"
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            onBlur={(e) => setFirstName(e.target.value.trim())}
                                            className={showErrors && !firstName ? ContactStyles.error : ''}
                                        />
                                    </div>
                                    {/*FIRSTNAME END*/}

                                    {/*LASTNAME START*/}
                                    <div className={ContactStyles.formField}>
                                        <label htmlFor={'contact-first-name'}>Dein Nachname*</label>
                                        <input
                                            id={'contact-last-name'}
                                            type="text"
                                            maxLength={100}
                                            autoComplete="family-name"
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            onBlur={(e) => setLastName(e.target.value.trim())}
                                            className={showErrors && !lastName ? ContactStyles.error : ''}
                                        />
                                    </div>
                                    {/*LASTNAME END*/}

                                    {/*EMAIL START*/}
                                    <div className={ContactStyles.formField}>
                                        <label htmlFor={'contact-email'}>Deine E-Mail-Adresse*</label>
                                        <input
                                            id={'contact-email'}
                                            type="text"
                                            maxLength={100}
                                            autoComplete="email"
                                            value={eMail}
                                            onChange={(e) => setEMail(e.target.value)}
                                            onBlur={(e) => setEMail(e.target.value.trim())}
                                            className={showErrors && !emailCheck(eMail) ? ContactStyles.error : ''}
                                        />
                                    </div>
                                    {/*EMAIL END*/}

                                    {/*PHONE START*/}
                                    <div className={ContactStyles.formField}>
                                        <label htmlFor={'contact-phone'}>
                                            Deine Telefonnummer{isPhoneMandatory && <>*</>}
                                        </label>
                                        <input
                                            id={'contact-phone'}
                                            type="text"
                                            maxLength={100}
                                            autoComplete="tel"
                                            value={phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                            onBlur={(e) => setPhoneNumber(e.target.value.trim())}
                                            className={
                                                showErrors && isPhoneMandatory && phoneNumber.length < 1
                                                    ? ContactStyles.error
                                                    : ''
                                            }
                                        />
                                    </div>
                                    {/*PHONE END*/}

                                    {/*SUBJECT START*/}
                                    <div className={ContactStyles.formField}>
                                        <label htmlFor={'contact-subject'}>Wie können wir dir helfen?*</label>
                                        <select
                                            id={'contact-subject'}
                                            value={subject}
                                            onChange={(e) => setSubject(e.target.value)}
                                            className={showErrors && !subject ? ContactStyles.error : ''}
                                        >
                                            <option value="">Bitte wählen</option>
                                            <option>Bestellung</option>
                                            <option>Lieferung</option>
                                            <option>Zahlung</option>
                                            <option>Horizon@Abo</option>
                                            <option>Benutzerkonto</option>
                                            <option>Allgemeine Fragen</option>
                                            <option>{SUPPORT}</option>
                                            <option>{RETOURE}</option>
                                        </select>
                                    </div>
                                    {/*SUBJECT END*/}

                                    {(subject === SUPPORT || subject === RETOURE) && (
                                        <>
                                            {/*MODEL START*/}
                                            <div className={ContactStyles.formField}>
                                                <label htmlFor={'contact-device'}>Modell*</label>
                                                <input
                                                    id={'contact-device'}
                                                    type="text"
                                                    maxLength={100}
                                                    value={device}
                                                    onChange={(e) => setDevice(e.target.value)}
                                                    onBlur={(e) => setDevice(e.target.value.trim())}
                                                    className={showErrors && !device ? ContactStyles.error : ''}
                                                />
                                            </div>
                                            {/*MODEL END*/}

                                            {/*SERIALNUMBER START*/}
                                            <div className={ContactStyles.formField}>
                                                <label htmlFor={'contact-serial-number'}>Seriennummer*</label>
                                                <div>
                                                    <input
                                                        id={'contact-serial-number'}
                                                        type="text"
                                                        maxLength={100}
                                                        value={serialNumber}
                                                        onChange={(e) => setSerialNumber(e.target.value)}
                                                        onBlur={(e) => setSerialNumber(e.target.value.trim())}
                                                        className={
                                                            showErrors && serialNumber.length < 1
                                                                ? ContactStyles.error
                                                                : ''
                                                        }
                                                    />
                                                    <p className={ContactStyles.info}>
                                                        Hinweis: Die Seriennummer des @Cycles befindet sich auf dem
                                                        Barcode-Aufkleber am vorderen Standfuß des Fitnessgeräts,
                                                        während die Seriennummer des @Mirrors auf der Rückseite am
                                                        Eingang des Stromkabels abgebildet ist.
                                                    </p>
                                                </div>
                                            </div>
                                            {/*SERIALNUMBER END*/}
                                        </>
                                    )}

                                    {/*MESSAGE START*/}
                                    <div className={ContactStyles.formField}>
                                        <label htmlFor={'message'}>Deine Nachricht*</label>
                                        <textarea
                                            rows={8}
                                            id={'message'}
                                            maxLength={800}
                                            value={message}
                                            placeholder="Max. 800 Zeichen"
                                            onChange={(e) => setMessage(e.target.value)}
                                            onBlur={(e) => setMessage(e.target.value.trim())}
                                            className={showErrors && !message ? ContactStyles.error : ''}
                                        />
                                    </div>
                                    {/*MESSAGE END*/}

                                    {/*AGREEMENT START*/}
                                    <div
                                        className={[
                                            CheckboxStyle.checkbox,
                                            ContactStyles.agreementField,
                                            showErrors && !agreement ? CheckboxStyle.error : '',
                                        ].join(' ')}
                                    >
                                        <input
                                            type="checkbox"
                                            id="agreement"
                                            onChange={(e) => {
                                                setAgreement(e.target.checked);
                                            }}
                                        />
                                        <label htmlFor="agreement" className="checkbox-label">
                                            Ich stimme zu, dass meine Angaben aus dem Kontaktformular zur Beantwortung
                                            meiner Anfrage erhoben und verarbeitet werden. Die Einwilligung kann
                                            jederzeit mit Wirkung für die Zukunft widerrufen werden. Detaillierte
                                            Informationen zum Umgang mit Nutzerdaten findest du in unserer
                                            Datenschutzerklärung.
                                        </label>
                                    </div>
                                    {/*AGREEMENT END*/}

                                    <p className="color-white">* Diese Angaben sind Pflichtfelder</p>

                                    <Button
                                        className={ContactStyles.button}
                                        type="button"
                                        onClick={submitForm}
                                        theme={{ type: ButtonThemeType.SolidWhite, color: ButtonThemeColor.Black }}
                                    >
                                        SENDEN
                                    </Button>
                                </form>
                            </div>
                        )}

                        {pageState === 'success' && (
                            <div className={ContactStyles.form}>
                                <p className="color-white">
                                    Vielen Dank für deine Nachricht! Wir haben deine Anfrage erhalten und werden uns
                                    schnellstmöglich um dich kümmern.
                                </p>
                            </div>
                        )}

                        {pageState === 'error' && (
                            <div className={ContactStyles.form}>
                                <p className="color-white">
                                    Leider ist ein unerwarteter Fehler aufgetreten. Bitte versuche es später noch
                                    einmal.
                                </p>
                            </div>
                        )}
                    </div>
                </SectionIntended>

                {pageState === 'start' && (
                    <div className={ContactStyles.teaser}>
                        <p className="bold">
                            Erreichbarkeit: Montag bis Freitag von 8.00 bis 20.00 Uhr. Samstag von 10.00 bis 14.00 Uhr.
                        </p>
                    </div>
                )}
            </div>
            <LoadingOverlay active={isLoading} />
        </PageLayout>
    );
};

export default ContactPage;
